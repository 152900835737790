iframe {
  display: none !important;
}
* {
  margin: 0px;
  padding: 0px;
}
img {
  max-width: 100%;
}
ul {
  margin: 0px;
  padding: 0px;
}
.btn {
  font-family: "Inter", sans-serif;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #7c7c7c;
  line-height: 23px;
  background: #f5f5f5;
}

.wrapper {
  max-width: 1460px;
  margin: auto;
  background: #fff;
}

.material-symbols-outlined {
  font-variation-settings:
    "FILL" 0,
    "wght" 400,
    "GRAD" 0,
    "opsz" 48;
  vertical-align: middle;
  display: inline-block;
  margin-right: 4px;
}

* {
  margin: 0px;
  padding: 0px;
}
dl,
ol,
ul {
  margin: 0px;
  padding: 0px;
}
img {
  max-width: 100%;
}
.text-right {
  text-align: right;
}

.headerTop {
  border-bottom: 1px solid #070d42;
}
.header {
  background: url(../images/headerTop.jpg) center center repeat-y;
}
.headerTop ul {
  margin: 0px;
  padding: 0px;
}
.headerTop ul li {
  display: inline-block;
  padding: 10px 30px 10px 0px;
}
.header .main_nav {
  // background: #070d42;
}
.header .main_nav ul {
}
.header .main_nav ul li {
  display: inline-block;
}
.header .main_nav ul li a {
  display: block;
  padding: 40px 30px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}
.header .main_nav ul li a.active,
.header .main_nav ul li a:hover {
  color: #fff;
  background: #040d58;
}
.footerTop {
  background: #040d58;
  color: #fff;
}
.footerTop ul {
  margin: 0px;
  padding: 0px;
}
.footerTop ul li {
  display: inline-block;
  padding: 10px 30px 10px 0px;
}
.footerTop ul li:last-child {
  padding-right: 0px;
}

.footerSection {
  background: #1c2254 url(../images/footer.jpg) center top no-repeat;
  padding: 60px 0px;
  color: #fff;
}

.footerLogoInner {
  padding-right: 60px;
  p {
    padding: 40px 0px;
  }
}

.footerSection {
  ul {
    margin: 0px;
    padding: 0px;
  }
  ul li {
    display: block;
  }
  ul li a {
    display: block;
    padding: 5px 0px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.footerSection h3 {
  font-family: "Quattrocento", serif;
  font-size: 21px;
  font-weight: bold;
}
.contactUsDetails {
  h1 {
    font-family: "Quattrocento", serif;
    font-size: 35px;
    color: #040d58;
  }
  p {
    color: #7c7c7c;
  }
}
.dateTag {
  background: #21393e;
  display: inline-block;
  padding: 5px 10px;
  color: #fff;
  margin-bottom: 10px;
}
.blog-list {
  padding: 40px 0px;
  margin: 0px;
  .inner {
    padding: 20px;
    border: 1px solid #7c7c7c;
    border-top: none;
  }

  h3 {
    font-family: "Quattrocento", serif;
    color: #21393e;
    font-size: 20px;
    line-height: 30px;
  }
  p {
    color: #7c7c7c;
  }
  a {
    background: #78716c;
    padding: 7px 14px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    border: 1px solid #78716c;
  }
  a:hover {
    color: #78716c;
    background: #fff;
    border: 1px solid #78716c;
  }
  p:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
.blog-details {
  margin: 40px 0px;
}
.blog-details-list {
  h2 {
    font-size: 24px;
    font-weight: normal;
    line-height: 40px;
    font-family: "Quattrocento", serif;
    font-weight: normal;
  }
  h1 {
    font-size: 30px;
    font-weight: normal;
    line-height: 40px;
    font-family: "Quattrocento", serif;
    font-weight: bold;
  }
  h3 {
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
  }
}

.capabilities-details {
  margin: 60px 0px;
  line-height: 25px;
  h1 {
    font-size: 43px;
    font-weight: normal;
    line-height: 53px;
    font-family: "Quattrocento", serif;
    font-weight: normal;
    color: #040d58;
    margin-bottom: 20px;
    padding: 0px;
  }
  p:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
.capabilities-list {
  padding: 40px 0px;
  h1 {
    font-size: 43px;
    font-weight: normal;
    line-height: 53px;
    font-family: "Quattrocento", serif;
    font-weight: normal;
    color: #040d58;
    margin-bottom: 20px;
    padding: 0px;
    text-align: center;
  }
  ul {
    li {
      display: inline-block;
      padding: 5px 10px;
      list-style: disc;
    }
    a {
      display: block;
      text-transform: uppercase;
      color: #333;
      text-decoration: none;
      font-weight: 500;
      border-bottom: transparent;
    }
    a.active {
      display: block;
      text-transform: uppercase;
      color: #040d58;
      text-decoration: none;
      border-bottom: 2px solid #d4c5af;
    }
  }
}
.divider {
  background: #e5dccf;
  height: 30px;
  margin: 30px 0px;
}
.quote {
  margin: 30px;
  padding-left: 30px;
  border-left: 2px solid #d4c5af;
}
.imagePlaceholder {
  width: 100%;
  height: 80%;
  border: 1px solid #ccc;
  margin: 20% 0px;
}
.capabilities-details {
  p {
    margin-bottom: 15px;
  }
  ul {
    margin-left: 20px;
    li {
      margin-bottom: 15px;
      padding: 0px;
    }
  }
  ol {
    counter-reset: custom-counter;
    list-style: disc;
    padding-left: 0;
    padding-left: 25px;
  }

  ol li {
    counter-increment: custom-counter;
    position: relative;

    margin-bottom: 15px;
    padding: 0px;
  }

  ol li::before {
    content: counter(custom-counter) ".";
    position: absolute;
    left: 0;
    color: #ff5733;
    font-weight: bold;
    display: none;
  }
}
.selection {
  background: #e5dccf;
  padding: 25px;
  margin-bottom: 50px;
  h3 {
    font-size: 30px;
    font-weight: bold;
    line-height: 40px;
    font-family: "Quattrocento", serif;
    color: #333;
    margin-bottom: 20px;
  }
  ul {
    margin: 0px 0px 0px 30px;
    padding: 0px;
    li {
      padding: 10px 10px 10px 20px;
      margin: 0px;
      display: block;
      border-left: 2px solid #d4c5af;
    }
  }
}
.people-details-content {
  h4 {
    font-size: 30px;
    font-weight: bold;
    line-height: 40px;
    font-family: "Quattrocento", serif;
    color: #333;
    margin: 15px 0px;
  }
  p {
    margin-bottom: 20px;
    padding: 0px;
  }
  ul {
    margin-left: 20px;
    margin-bottom: 40px;
    li {
      margin-bottom: 15px;
      padding: 0px;
    }
  }
}
.logoTeam {
  background: url(../images/logoTeam.jpg) center bottom no-repeat;
  h1 {
    padding-bottom: 100px;
  }
}
.teamPhoto.selection {
  padding: 0px;
}

.infobox {
  h1 {
    color: #000000;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid #d4c5af;
    display: inline-block;
  }
}
.infobox2 {
  background: #e5dccf;
  padding: 20px;
  display: flex;
  margin: 50px 0px;
  .education {
    border-right: 2px solid #d4c5af;
    margin-right: 40px;
    padding-right: 40px;
    color: #000000;
  }
  .education:last-child {
    border: none;
  }
}
.homeBlog {
  padding: 40px 0px;
  h1 {
    font-size: 43px;
    font-weight: normal;
    line-height: 53px;
    font-family: "Quattrocento", serif;
    font-weight: normal;
    color: #040d58;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #d4c5af;
    display: inline-block;
  }
  a {
    background: #040d58;
    padding: 7px 14px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    border: 1px solid #78716c;
  }
  a:hover {
    background: #78716c;
    color: #fff;
  }
}

.homePractice {
  padding: 40px 0px;
  h1 {
    font-size: 43px;
    font-weight: normal;
    line-height: 53px;
    font-family: "Quattrocento", serif;
    font-weight: normal;
    color: #040d58;
    display: block;
  }
  a {
    background: #040d58;
    padding: 7px 14px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    border: 1px solid #78716c;
  }
  a:hover {
    background: #78716c;
  }
}
.accordion {
  .accordionItem {
    padding: 20px 0px;
    border-bottom: 1px solid #b98e44;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .accordionItemIcon {
      font-size: 30px;
      padding: 10px;
    }
    p {
      margin: 0px;
      padding: 0px;
    }
    h2 {
      font-size: 23px;
      font-weight: normal;
      line-height: 33px;
      font-family: "Quattrocento", serif;
      font-weight: normal;
      color: #040d58;
      display: block;
    }
  }
}

.homeContent {
  max-width: 1000px;
  margin: auto;
  padding: 60px;
  position: relative;
  h3 {
    font-size: 43px;
    font-weight: normal;
    line-height: 63px;
    font-family: "Quattrocento", serif;
    font-weight: normal;
    color: #040d58;
    display: block;
    margin: 0px 0px 20px 0px;
    span {
      color: #d1b48c;
    }
  }
  a {
    background: #040d58;
    padding: 7px 14px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    border: 1px solid #78716c;
  }
  a:hover {
    background: #78716c;
    color: #fff;
  }
}

.homeContent::before {
  position: absolute;
  left: 0px;
  top: 0px;
  content: "";
  width: 2px;
  font-size: 0px;
  background: #d1b48c;
  height: 90%;
  border-radius: 8px;
}

.homeContent::after {
  position: absolute;
  left: 0px;
  top: -100px;
  content: "";
  width: 2px;
  font-size: 0px;
  background: #d1b48c;
  height: 90%;
  border-radius: 8px;
}
.homeContentWrapper {
  position: relative;
}
.homeContentWrapper::after {
  position: absolute;
  left: 0px;
  top: -100px;
  content: "";
  width: 100px;
  font-size: 0px;
  background: #d1b48c;
  height: 2px;
  border-radius: 8px;
}
.our-philosophy {
  background: url(../images/logoLegalAdvice.jpg) center top no-repeat;
  padding-top: 100px;

  h1 {
    font-size: 43px;
    font-weight: normal;
    line-height: 63px;
    font-family: "Quattrocento", serif;
    font-weight: normal;
    color: #040d58;
    display: block;
    margin: 0px 0px 20px 0px;
    position: relative;
    margin-bottom: 40px;
  }
  h1::before {
    position: absolute;
    left: 0px;
    bottom: -20px;
    content: "";
    width: 100px;
    font-size: 0px;
    background: #d1b48c;
    height: 2px;
    border-radius: 8px;
  }
  h3 {
    font-size: 23px;
    font-weight: bold;
    line-height: 33px;
    font-family: "Quattrocento", serif;
    color: #040d58;
    margin-bottom: 40px;
  }
}

.our-philosophy.noimage {
  background: none !important;
  padding: 50px 0px;
}
.list {
  padding-bottom: 130px;
  border-left: 2px solid #d1b48c;
  padding-left: 120px;
  background: url(../images/icon.jpg) 30px 15px no-repeat;
  h2 {
    font-size: 43px;
    font-weight: normal;
    line-height: 63px;
    font-family: "Quattrocento", serif;
    font-weight: normal;
    color: #040d58;
    display: block;
    margin: 0px 0px 20px 0px;
  }
}
.list:last-child {
  padding-bottom: 0px;
}

.what-sets-apart {
  padding: 100px 0px;
  h1 {
    font-size: 40px;
    font-weight: normal;
    line-height: 55px;
    font-family: "Quattrocento", serif;
    font-weight: bold;
    color: #040d58;
    display: block;
    margin: 0px 0px 20px 0px;
    position: relative;
    margin-bottom: 60px;
  }
  h1::before {
    position: absolute;
    left: 0px;
    bottom: -10px;
    content: "";
    width: 100px;
    font-size: 0px;
    background: #d1b48c;
    height: 2px;
    border-radius: 8px;
  }

  h2 {
    font-size: 26px;
    font-weight: normal;
    line-height: 33px;
    font-family: "Quattrocento", serif;
    font-weight: bold;
    color: #040d58;
    display: block;
    padding-bottom: 15px;
    position: relative;
  }
  h2::before {
    position: absolute;
    left: -25px;
    top: 0px;
    content: "";
    height: 30px;
    font-size: 0px;
    background: #d1b48c;
    width: 2px;
    border-radius: 8px;
  }
  .inner {
    padding-left: 40px;
  }
}
.short_description {
  margin-bottom: 15px;
}
.main_nav.main_nav_mobile {
  display: none;
}
.popup-overlay {
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 122;
  width: 100%;
  height: 100vh;
  background: #00000090;
}
.popup {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 222;
  max-width: 600px;
  height: auto;
  padding: 30px;
  background: #fff;
  transform: translate(-50%, -50%);
  h2 {
    font-size: 26px;
    font-weight: normal;
    line-height: 33px;
    font-family: "Quattrocento", serif;
    font-weight: bold;
    color: #040d58;
    display: block;
    padding-bottom: 15px;
    position: relative;
  }
  .custom-flex {
    display: flex;
    justify-content: space-between;
  }
  .btn.btn-outline-primary.red{
    background: #fff;
    color: #ff5733;
    border: 1px solid #ff5733 !important;
  }
  .btn.btn-outline-primary{
    background: #fff;
    color: #040d58;
    border: 1px solid #040d58 !important;
  }

  .btn-outline-primary.red:hover,
  .btn-outline-primary.red:focus {
    background: #ff5733;
    color: #fff;
    border: 1px solid #ff5733 !important;
  }

  .btn-outline-primary:hover,
  .btn-outline-primary:focus {
    background: #040d58;
    color: #fff;
    border: 1px solid #040d58 !important;
  }

}

@media screen and (max-width: 990px) {
  .headerTop {
    display: none;
  }
  .homeContent h3 {
    font-size: 25px;
    line-height: 35px;
  }
  .homePractice h1,
  .homeBlog h1,
  .capabilities-details h1,
  .our-philosophy h1,
  .what-sets-apart h1,
  .list h2 {
    font-size: 31px;
    font-weight: bold;
    line-height: 38px;
  }
  .footerTop ul li {
    display: block;
    text-align: center;
  }
  .capabilities-details {
    margin: 30px 0px;
  }
  .footerLogoInner {
    padding-right: 0px;
    p {
      padding: 25px 0px;
    }
  }
  .footerSection,
  .our-philosophy.noimage,
  .our-philosophy,
  .what-sets-apart {
    padding: 30px 0px;
  }
  .list {
    padding-bottom: 40px;
  }
  .what-sets-apart h2 {
    margin-bottom: 0px;
  }
  .what-sets-apart .inner {
    margin-bottom: 30px;
  }
  .list {
    padding-top: 20px;
    p {
      margin: 0px;
    }
  }
  .overlay {
    background: #00000090;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .innerMenu {
    background: #040d58;
    position: fixed;
    right: 0px;
    top: 0px;
    width: 70%;
    height: 100%;
    z-index: 2;
    padding: 20px 12px;
  }
  .main_nav.main_nav_mobile {
    display: block;
  }
  .header .logoWraper {
    padding: 15px 0px;
    background: #fff;
  }
  .hangerMenuOpen {
    margin: 20px 0px;
  }
  .innerMenu ul {
  }
  .header .main_nav ul li {
    display: block;
    text-align: left;
  }
  .header .main_nav ul li a {
    padding: 15px;
    border-bottom: 1px solid #ffffff90;
  }
  .btn-outline-primary,
  .btn-outline-primary:hover,
  .btn-outline-primary:focus {
    background: #fff;
    color: #000000;
    border: none !important;
  }
  .capabilities-list .btn-outline-primary .lineBox {
    width: 30px;
    height: 25px;
  }
  .capabilities-list .btn-outline-primary .lineBox .line {
    border-bottom: 2px solid #ccc;
    display: block;
    margin-bottom: 8px;
  }
  .capabilities-list .btn-outline-primary {
    display: flex;
    gap: 16px;
    border: 1px solid #040d58;
    background: #040d58;
    color: #fff;
    margin: auto;
    width: 250px;
    padding: 16px;
  }
  ul.practice-areas-menu {
    display: block;
    overflow: scroll;
    height: 100vh;
  }
  ul.practice-areas-menu li {
    display: block;
  }
  ul.practice-areas-menu li a {
    display: block;
    padding: 5px 10px;
  }
  .capabilities-list {
    margin-bottom: 0px;
    padding: 0px;
  }
}
